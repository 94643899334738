<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>READING: Multiple Choice (Multiple)
            </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'mcs_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>READING: Multiple Choice (Multiple)</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You'll get text, read that text and answer the multiple-choice question by selecting all the correct responses. More than one response is correct.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 2 - 3 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You will get a passage of upto 300 words on screen. You need to go through the text. Below the text you will get a question with multiple options. You need to select more than one option which would be the correct answers.</li>
                <li>Read the question and find out the points to focus. Have a look at the question and the options, start reading the text. Choose the best acceptable options.</li>
                <li>On an average, you should not be spending more than 2 minutes on each question.</li>
            </ul>
            </template>
            <app-ReadingExtraInfo />
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Multiple Choice (Multiple)</h4>
            <p class="questHeading">Read the text and answer the question by selecting all the correct responses. More than one response is correct.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>Time </p><app-Timer ref="timer" :increment="true"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :type="'double'" :selectedQuestion="selectedquestion" :question_id="selectedquestion.mcs_id" :idName="'mcs_id'" :tableName="'tblMultipleChioce'"/>
            </div>
            </div>
            
            <p class="questdesc">{{selectedquestion.msc_desc}}</p>
            <p class="questionMark">{{selectedquestion.msc_question}}</p>
            <div id="answerList">
                <ol class="mt-3">
                    <li v-for="item in answerLists" :key="item.mscs_id" :id="item.mcsc_id" class="mt-3">
                    <label>
                        <input type="checkbox"
                        v-on:change="toggle(item)"
                        v-bind:checked="item.done">

                        <span v-if="item.done">
                        {{ item.mcsc_options }}
                        </span>
                        <span v-else>
                        {{ item.mcsc_options }}
                        </span>
                    </label>
                    </li>
                </ol>
            </div>

            <div class="row">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="answerSubmit">
                    <p style="padding-left:0px!important;color:#fff;"><strong>Your Score:   <span class="pl-1">{{score_count}}</span>/{{total_mark}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li v-for="item in actualAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.mcsc_options}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12 mb-4 results" v-if="checkAnswerFlag == true">
                    <h5>Result:- </h5>
                    <p><strong>Your result is {{userAnswer.user_score}} out of {{total_mark}}</strong></p>
                </div>
                <div class="col-12 text-center">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
                </div>
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'mcs_id'"/></b-container>
        </b-modal>
        <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="dictText" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblusermcmultipleanswer'"/>
        </div> 
    </div>
</template> 
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import $ from "jquery";
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
import DiscussionTab from '../reuseable/discussionTab'
import ReadingExtraInfo from '../reuseable/readingInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService';
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-dictTemp': dictTemplate,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab,
        'app-ReadingExtraInfo':ReadingExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            answerLists:{
                type: Object,
                required: true
            },
            correctAnswer:null,
            currentAnswer: null,
            question_id:0,
            user_id:0,
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                multicall:'T',
                answers:'',   
                url:'',
                mock_test_id:0,
                user_score: '' //if 0 then answer is not correct if 1 then answer is correct
            },
            options:[],
            score_count: 0,
            checkAnswerFlag: false,
            total_mark:0,
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:null,
            answerSubmit:false,
            searchKey:'',
            searchQuestionList:null,
            isShowModal: false,
            dictText:'',
            modelTitle:'heading',
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'writtingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'writtingmodule/AnswerResponse'
        }),
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        reset(){
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                multicall:'T',
                answers:'',   
                url:'',
                user_score: '' //if 0 then answer is not correct if 1 then answer is correct
            }
            this.answerLists={
                type: Object,
                required: true
            }
            this.answerSubmit = false
            this.checkAnswerFlag=false
            this.total_mark=0
            this.userAnswer.user_score=''
            this.actualAnswer = null
        },
        showModal() {
            this.dictText= this.selectedquestion.msc_desc
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].mcs_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.mcs_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.reset()
            this.currentQuestion=questionId
            this.question_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.mcs_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
                 //console.log(this.selectedquestion)
            this.modelTitle = this.selectedquestion.title
            this.questionservice('configmultiplechoicechild/id='+questionId).then(()=>{
            this.answerLists = this.getQuestionList().data
                //console.log(this.answerLists)
                this.actualAnswer =this.answerLists.filter((item)=>{
                return item.correct_val_ind == "T"
                })
            }) 
            this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)
            this.$refs.timer.setTime(0)
            })
            this.removeClasses()
        },
        toggle: function(todo){
            todo.done = !todo.done
        },
        async checkAnswer(){
            var vm = this;
            this.total_mark=0
            this.score_count=0
            this.checkAnswerFlag = true;
            this.correctAnswer= this.answerLists.filter((item)=>{
                return item.correct_val_ind == "T" & item.done == false
            })
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })
            $.each(this.currentAnswer, function(){
                if(this.correct_val_ind == "T"){
                    $("#"+this.mcsc_id).addClass('rightAnswer')
                    vm.total_mark++
                    vm.score_count++
                }
                else{
                    $("#"+this.mcsc_id).addClass('wrongAnswer')
                    vm.score_count--
                }
            })
            $.each(this.correctAnswer, function(){
                $("#"+this.mcsc_id).addClass('rightAnswer')
                vm.total_mark++
                this.done = true
            })
            if(vm.score_count<0) vm.score_count=0
                vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.total_mark
        },
        removeClasses(){
            $('li.wrongAnswer').removeClass('wrongAnswer')
            $('li.rightAnswer').removeClass('rightAnswer')
        },
        timerEnd(){
            //console.log("end of time")
        },
        async submitanswer() {

           await this.checkAnswer();
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })
            var vm = this;
            var index = 0;
            this.options = []
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            $.each(this.currentAnswer, function(){

                const tempCt= {
                    user_id: vm.user_id,
                    mcs_id: vm.currentAnswer[index].mcs_id,
                    mcsc_id: vm.currentAnswer[index].mcsc_id
                }
                index++;
                vm.options.push(tempCt);
            })
            //console.log(this.currentAnswer);

            this.userAnswer.answers = this.options;
            this.userAnswer.mock_test_id=0
            this.userAnswer.url = 'usermultiplechoiceanswer/createanswer';

            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    // alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
                this.answerSubmit = true
                
        },
        resetAll() {
            $.each(this.answerLists, function(){
                this.done = false
            });
            this.score_count = 0;
            this.checkAnswerFlag = false;
            this.userAnswer = {
                multicall:'T',
                answers:'',
                url:'',
                user_score:'',
                user_id:this.$ls.get('user').user_id,
                mock_test_id:0
            }
        }
    },
    beforeMount(){
        this.questionservice('configmultiplechoice/getallmultipleoptonsmultiple').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
           this.userAnswer.user_id= this.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        })
    }
}
</script>
<style scoped>
.text-center button{
    margin-right:30px
}
</style>